.container{
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* gap: 12px; */
  width: 100vw;
  height: 100vh;
  padding: 16px;
  box-sizing: border-box;
  flex-direction: column;
  overflow: auto;
}

.container>*{
  width: 100%;
  /* padding: 8px; */
  box-sizing: border-box;
  
}

.header>*{
  margin: 0;
}

.header{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-bottom: 2px var(--foreground-color) solid;
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}

.content>div>*{
  flex-shrink: 0;
}

.dealerCards, .playerCards{
  max-width: 100%;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  /* justify-content: center; */

}

/* .dealerCards{
  justify-content: space-between;
} */

.dealerCards>div{
  display: flex;
  gap: 8px;
}

.playerCards{
  /* justify-content: flex-end; */
}

.actionsPanel{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.actionsPanel>p{
  margin: 0;
  /* font-size: 28px; */
  font-size: 4vh;
  text-align: center;
}

.cardValueMonitor>p{
  /* font-size: 22px; */
  font-size: 3vh;
  /* color: var(--foreground-color); */
  font-weight: 400;
  margin-block: 8px;
  text-align: center;
}

.cardValueMonitor>p>span{
  font-weight: 700;
  /* color: var(--text-color); */
}

.hoversRed:hover{
  background-color: red;
}

.hoversGreen:hover{
  background-color: green;
}

.scorePanel{
  display: flex;
  align-items: center;
  gap: 8px;
  /* flex-direction: column; */
}

.scorePanel>button{
  font-size: 14px;
  padding: 4px;
  /* margin-top: 4px; */
  opacity: 0.5;
}

.scorePanel>p{
  margin: 0;
  color: #888;
}

/* .header{
  background-color: red;
}

.content{
  background-color: green;
}

.actionsPanel{
  background-color: blue;
} */