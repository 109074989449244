@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap);
body{
  --theme-transition: 0.5s ease;
}

body{
  --background-color: black;
  --text-color: white;
  --foreground-color: #222;
  --card-red: #d83636;
  --card-black: white;
}

body.theme-light{
  --background-color: #ddd;
  --text-color: black;
  --foreground-color: white;
  --card-red: #ce1414;
  --card-black: black;
}

/* svg.card>#SD9, svg.card>#VD9, svg.card>#SH9, svg.card>#VH9 { */
.card-red symbol>path{
  fill: var(--card-red);
  stroke: var(--card-red);
}

/* svg.card>#SC9, svg.card>#VC9, svg.card>#SS9, svg.card>#VS9 { */
.card-black symbol>path {
  fill: var(--card-black);
  stroke: var(--card-black);
}

svg.card *{
  transition: fill var(--theme-transition), stroke var(--theme-transition);
}

svg *[fill="none"]{
  fill: none !important;
}
svg *[stroke="none"]{
  stroke: none !important;
}

svg use[stroke="#44F"]{
  stroke: none;
}
svg use[fill="white"]{
  fill: none;
}

svg.card>rect{
  fill: var(--foreground-color);
  stroke: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: color var(--theme-transition), background-color var(--theme-transition);
  width: 100vw;
  height: 100vh;
}

* {
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 8px;
  border-radius: 6px;
  background-color: var(--foreground-color);
  border: none;
  color: var(--text-color);
  font-size: large;
  transition: color var(--theme-transition), background-color var(--theme-transition), -webkit-transform 240ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: color var(--theme-transition), background-color var(--theme-transition), transform 240ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: color var(--theme-transition), background-color var(--theme-transition), transform 240ms cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 240ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
button:hover{
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}

button:active{
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

.buttonPanel{
  display: -webkit-flex;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.App_container__1MQN3{
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* gap: 12px; */
  width: 100vw;
  height: 100vh;
  padding: 16px;
  box-sizing: border-box;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: auto;
}

.App_container__1MQN3>*{
  width: 100%;
  /* padding: 8px; */
  box-sizing: border-box;
  
}

.App_header__3ZZ1n>*{
  margin: 0;
}

.App_header__3ZZ1n{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: space-between; */
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 16px;
  gap: 16px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-bottom: 14px;
  margin-bottom: 16px;
  border-bottom: 2px var(--foreground-color) solid;
}

.App_content__3La4L{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow: hidden;
  -webkit-align-items: center;
          align-items: center;
}

.App_content__3La4L>div>*{
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.App_dealerCards__8XnEs, .App_playerCards__1kB9L{
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  /* justify-content: center; */

}

/* .dealerCards{
  justify-content: space-between;
} */

.App_dealerCards__8XnEs>div{
  display: -webkit-flex;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.App_playerCards__1kB9L{
  /* justify-content: flex-end; */
}

.App_actionsPanel__1bXTr{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 12px;
}

.App_actionsPanel__1bXTr>p{
  margin: 0;
  /* font-size: 28px; */
  font-size: 4vh;
  text-align: center;
}

.App_cardValueMonitor__VQK_e>p{
  /* font-size: 22px; */
  font-size: 3vh;
  /* color: var(--foreground-color); */
  font-weight: 400;
  margin-block: 8px;
  text-align: center;
}

.App_cardValueMonitor__VQK_e>p>span{
  font-weight: 700;
  /* color: var(--text-color); */
}

.App_hoversRed__FIUsG:hover{
  background-color: red;
}

.App_hoversGreen__3kNcl:hover{
  background-color: green;
}

.App_scorePanel__36Grk{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 8px;
  gap: 8px;
  /* flex-direction: column; */
}

.App_scorePanel__36Grk>button{
  font-size: 14px;
  padding: 4px;
  /* margin-top: 4px; */
  opacity: 0.5;
}

.App_scorePanel__36Grk>p{
  margin: 0;
  color: #888;
}

/* .header{
  background-color: red;
}

.content{
  background-color: green;
}

.actionsPanel{
  background-color: blue;
} */

.card_cardContainer__ZVibh{
	transition: -webkit-filter 240ms ease;
	transition: filter 240ms ease;
	transition: filter 240ms ease, -webkit-filter 240ms ease;
	/* width: 160px; */
	max-height: 100%;
	height: 30vh;
}

.card_cardContainer__ZVibh>div,
.card_cardContainer__ZVibh>div>svg{
	width: 100%;
	height: 100%;
}

/* .cardContainer:hover{
	filter: brightness(1.4);
}

.cardContainer:active{
	filter: brightness(1.2);
} */
