
.cardContainer{
	transition: filter 240ms ease;
	/* width: 160px; */
	max-height: 100%;
	height: 30vh;
}

.cardContainer>div,
.cardContainer>div>svg{
	width: 100%;
	height: 100%;
}

/* .cardContainer:hover{
	filter: brightness(1.4);
}

.cardContainer:active{
	filter: brightness(1.2);
} */